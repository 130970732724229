import { ReactElement, useState } from "react";
import { ProductItemInterface } from "../../../assets/interfaces";
import { toAppUrl, toCurrency } from "../../../assets/js/utils/functions";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import { useModals } from "../../hooks/useModals";
import LazyImage from "../../lazy-image";
import { AppBtn } from "../../ui/buttons";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";
import ImageCarouselModal from "../image-carousel-modal";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  product: ProductItemInterface;
  openEditModal?: () => void;
  itemPage?: boolean;
  closeable?: boolean;
  showVariantsModal?: () => void;
}

const ProductDetailsModal: React.FC<Props> = (props) => {
  const {
    show,
    toggle,
    product,
    openEditModal,
    itemPage = false,
    children,
    closeable = true,
    showVariantsModal,
  } = props;
  const { modals, toggleModal } = useModals(["image_carousel"]);
  const [currentImage, setCurrentImage] = useState(0);
  const [copied, copy] = useCopyClipboard(toAppUrl(`products/${product?.slug}`), {
    successDuration: 1000,
  });

  const showFullImage = (index: number) => {
    setCurrentImage(index);
    toggleModal("image_carousel");
  };

  return (
    <>
      <Modal
        {...{ show, toggle }}
        title={`Product Details - ${product.name}`}
        closeable={closeable}
        noBg={!closeable}
        bgClose={itemPage ? false : true}
      >
        <ModalBody>
          <div className="-mx-5 sm:-mx-6.25 overflow-hidden mb-3.75">
            <div
              className="grid gap-3 overflow-x-auto"
              style={{ gridTemplateColumns: `10px repeat(${product?.images.length}, 25%) 10px` }}
            >
              <figure className="w-5"></figure>
              <ProductImages {...{ images: product?.images, showFullImage }} />
              <figure className="w-5"></figure>
            </div>
          </div>
          <dl className="flex flex-col space-y-3.75 pt-3.75 border-t border-grey-divider">
            <div className="flex w-full justify-between items-center">
              <dt className="text-sm sm:text-1sm text-grey-subtext">Product Name:</dt>
              <dd className="text-dark font-semibold text-sm sm:text-1sm capitalize">{product?.name}</dd>
            </div>
            {!itemPage && (
              <div className="flex w-full justify-between items-center">
                <dt className="text-sm sm:text-1sm text-grey-subtext">Views:</dt>
                <dd className="text-dark font-semibold text-sm sm:text-1sm capitalize">{product?.views}</dd>
              </div>
            )}
            {product?.variants?.options.length > 0 && !itemPage && (
              <div className="flex w-full justify-between items-center">
                <dt className="text-sm sm:text-1sm text-grey-subtext">Options:</dt>
                <dd className="text-dark font-semibold text-sm sm:text-1sm capitalize">
                  <button
                    className="underline no-outline text-grey-subtext hover:text-primary-500"
                    onClick={showVariantsModal}
                  >
                    {product?.variants.options.length} options
                  </button>
                </dd>
              </div>
            )}
            <div className="flex w-full justify-between items-center">
              <dt className="text-sm sm:text-1sm text-grey-subtext">Product Link:</dt>
              <dd className="inline-flex items-center -mt-1" onClick={() => copy()}>
                <h4 className="text-sm font-semibold text-primary max-w-[200px] lg:max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer">
                  {copied ? "Copied!" : toAppUrl(`products/${product?.slug}`, false)}
                </h4>
                <button
                  type="button"
                  className="h-5 w-5 rounded-5 bg-primary-80 hover:bg-primary-100 flex items-center justify-center ml-1.25 text-primary-500"
                >
                  <svg width="12" viewBox="0 0 10 10" fill="none">
                    <path
                      d="M8.33333 3.75H4.58333C4.1231 3.75 3.75 4.1231 3.75 4.58333V8.33333C3.75 8.79357 4.1231 9.16667 4.58333 9.16667H8.33333C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333V4.58333C9.16667 4.1231 8.79357 3.75 8.33333 3.75Z"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.08337 6.25016H1.66671C1.44569 6.25016 1.23373 6.16237 1.07745 6.00608C0.921171 5.8498 0.833374 5.63784 0.833374 5.41683V1.66683C0.833374 1.44582 0.921171 1.23385 1.07745 1.07757C1.23373 0.921293 1.44569 0.833496 1.66671 0.833496H5.41671C5.63772 0.833496 5.84968 0.921293 6.00596 1.07757C6.16224 1.23385 6.25004 1.44582 6.25004 1.66683V2.0835"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </dd>
            </div>
            {product.category && (
              <div className="flex w-full justify-between items-center">
                <dt className="text-sm sm:text-1sm text-grey-subtext">Product Category:</dt>
                <dd className="text-dark font-semibold text-sm sm:text-1sm">
                  {product.category ? `${product.category.emoji} ${product.category.name}` : "-"}
                </dd>
              </div>
            )}
            <div className="flex w-full justify-between items-center">
              <dt className="text-sm sm:text-1sm text-grey-subtext">Price:</dt>
              <dd className="text-dark font-semibold text-sm sm:text-1sm">{toCurrency(product.price)}</dd>
            </div>
            {product.discount_price && (
              <div className="flex w-full justify-between items-center">
                <dt className="text-sm sm:text-1sm text-grey-subtext">Discount Price:</dt>
                <dd className="text-dark font-semibold text-sm sm:text-1sm">
                  {toCurrency(product.discount_price) || "-"}
                </dd>
              </div>
            )}
            {product.price_unit && (
              <div className="flex w-full justify-between items-center">
                <dt className="text-sm sm:text-1sm text-grey-subtext">Price Unit:</dt>
                <dd className="text-dark font-semibold text-sm sm:text-1sm">{product.price_unit || "-"}</dd>
              </div>
            )}
            <div className="flex w-full justify-between items-center">
              <dt className="text-sm sm:text-1sm text-grey-subtext">Availability:</dt>
              <dd
                className={`${
                  product.available ? "text-accent-green-500" : "text-accent-red-500"
                } font-semibold text-sm sm:text-1sm`}
              >
                {product.available ? "Available" : "Unavailable"}
              </dd>
            </div>
            <div className="flex w-full flex-col space-y-2">
              <dt className="text-sm sm:text-1sm text-grey-subtext">Description:</dt>
              <dd className="text-dark font-semibold text-sm sm:text-1sm whitespace-pre-line">{product.description}</dd>
            </div>
          </dl>
        </ModalBody>
        {(openEditModal || children) && (
          <ModalFooter>
            {openEditModal ? (
              <AppBtn isBlock onClick={() => openEditModal()}>
                Edit Product
              </AppBtn>
            ) : (
              children
            )}
          </ModalFooter>
        )}
      </Modal>
      <ImageCarouselModal
        show={modals.image_carousel.show}
        toggle={() => toggleModal("image_carousel")}
        images={product.images}
        current={currentImage}
      />
    </>
  );
};

export const ProductImages: React.FC<{ showFullImage: (index: number) => void; images: string[] }> = ({
  showFullImage,
  images,
}) => {
  return (
    <>
      {images.map((image, index) => (
        <figure
          key={index}
          className="w-full relative overflow-hidden rounded-15"
          style={{ paddingTop: "100%", cursor: "zoom-in" }}
          onClick={() => showFullImage(index)}
        >
          <LazyImage src={image} alt="" className="w-full h-full object-cover absolute top-0 left-0 z-10 rounded-15" />
        </figure>
      ))}
    </>
  );
};

export const ImageLoaderBg = () => {
  return <div className="bg-grey-loader h-full w-full absolute top-0 animate-pulse"></div>;
};

export default ProductDetailsModal;
