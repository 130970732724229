import React, { useState } from "react";
import BetaForm from "../beta-form";
import { useModals } from "../hooks/useModals";
import Link from "next/link";
import { socialsDataMap } from "../store-front/details";

interface Props {
  mode?: "light" | "dark";
  storeName: string;
  hasPaidSubscription: boolean;
  socials: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
  };
  refund_policy?: string;
  className?: string;
}

const PublicFooter: React.FC<Props> = ({
  mode = "light",
  socials,
  storeName,
  refund_policy,
  hasPaidSubscription,
  className,
}) => {
  const socialsData = Object.entries(socials ?? {})
    .filter((e) => !!e?.[1])
    .map((e) => ({ type: e[0], handle: e[1] }));

  const [showMorePolicy, setShowMorePolicy] = useState(false);

  return (
    <footer
      className={`border-t sm:px-9 md:px-12.5 mx-auto md:max-w-[1000px] lg:max-w-[1100px] pb-5 sm:pb-6.25 justify-between border-opacity-40 px-5 ${
        mode === "light" ? "border-grey-border" : "border-grey-divider"
      } ${className} `}
    >
      <div className="w-full">
        {socialsData.length > 0 && (
          <div className="mt-6.25">
            <h3 className="font-bold text-base sm:text-[17px] text-black-secondary">Our Socials:</h3>
            <div className="mt-1.25 flex items-center space-x-3">
              {socialsData.map((s, index) => (
                <a
                  className="flex items-center text-add-to-cart"
                  key={index}
                  href={`${socialsDataMap[s.type].prefix}/${s.handle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <figure
                    className={`h-6.25 w-6.25 sm:h-6.75 sm:w-6.75 rounded-full flex items-center bg-grey-fields-100 p-1.5`}
                  >
                    {socialsDataMap[s.type].icon}
                  </figure>
                  <span className="inline-block ml-2 text-1xs sm:text-sm capitalize font-medium">{s.type}</span>
                  {/* prettier-ignore */}
                  <svg className="w-2 sm:w-2.5 mt-0.5 ml-1" viewBox="0 0 10 10" fill="none">
                    <path d="M1.24264 0.24265V1.73818L7.16643 1.74348L0.71231 8.1976L1.77297 9.25826L8.22709 2.80414L8.23239 8.72793H9.72792V0.24265H1.24264Z" fill="currentColor"/>
                  </svg>
                </a>
              ))}
            </div>
          </div>
        )}

        {refund_policy && (
          <div className="mt-6.25">
            <h3 className="font-bold text-base sm:text-[17px] text-black-secondary mb-1.25">Refund Policy</h3>
            <div className="text-dark text-sm lg:max-w-[700px] whitespace-pre-wrap ">
              <span className={`${!showMorePolicy && "elipsis-2"}`}>{refund_policy}</span>
              {
                <button
                  onClick={() => setShowMorePolicy(!showMorePolicy)}
                  className="text-primary-500 block font-medium"
                >
                  {!showMorePolicy ? "Show more" : "Hide more"}
                </button>
              }
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-between flex-wrap">
        <div className="flex items-center mt-6.25">
          <span
            className={`text-sm font-medium inline-block pr-2.5 mr-2.5 border-r border-grey-border ${
              mode === "light" ? "text-black-muted" : "text-white"
            }`}
          >
            &copy; {new Date().getFullYear()}
          </span>
          <span className={`text-sm font-medium inline-block ${mode === "light" ? "text-black-muted" : "text-white"}`}>
            {storeName}
          </span>
        </div>
        <a
          href={process.env.NEXT_PUBLIC_DASHBOARD_URL}
          target="_blank"
          rel="noreferrer"
          className="bg-grey-fields-100 px-3.75 py-1.5 rounded-[100px] text-1xs text-dark flex items-center font-medium space-x-1.5 mt-6.25"
        >
          <span>Powered by</span>
          <img src="/images/logos/primary.svg" alt="Catlog logo" className="h-4" />
        </a>
      </div>
      {/* <div className="flex items-center justify-center md:justify-start space-x-5">
        {socials?.twitter && (
          <a
            href={`https://twitter.com/${socials?.twitter}`}
            target="_blank"
            rel="noreferrer"
            className={`flex items-center text-sm font-medium transition-colors duration-75 ${
              mode === "light" ? "text-dark hover:text-primary-500" : "text-grey-divider hover:text-accent-yellow-500"
            }`}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M15.3332 1.99987C14.6948 2.45019 13.9879 2.79461 13.2398 3.01987C12.8383 2.55821 12.3047 2.231 11.7112 2.08249C11.1177 1.93397 10.4928 1.97133 9.92122 2.1895C9.34961 2.40768 8.8588 2.79614 8.51516 3.30235C8.17152 3.80856 7.99163 4.40809 7.99984 5.01987V5.68653C6.82826 5.71691 5.66735 5.45708 4.62051 4.93017C3.57367 4.40325 2.67338 3.62562 1.99984 2.66653C1.99984 2.66653 -0.666829 8.66653 5.33317 11.3332C3.96019 12.2652 2.32461 12.7325 0.666504 12.6665C6.6665 15.9999 13.9998 12.6665 13.9998 4.99987C13.9992 4.81417 13.9814 4.62893 13.9465 4.44653C14.6269 3.77553 15.1071 2.92834 15.3332 1.99987V1.99987Z"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="hidden md:inline-block pt-0.5 ml-1.5 max-w-[120px] overflow-hidden overflow-ellipsis whitespace-nowrap">
              @{socials?.twitter}
            </span>
          </a>
        )}
        {socials?.instagram && (
          <a
            href={`https://instagram.com/${socials?.instagram}`}
            target="_blank"
            rel="noreferrer"
            className={`flex items-center text-sm font-medium transition-colors duration-75 hover:text-primary-500 ${
              mode === "light" ? "text-dark hover:text-primary-500" : "text-grey-divider hover:text-accent-yellow-500"
            }`}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M11.3335 1.3335H4.66683C2.82588 1.3335 1.3335 2.82588 1.3335 4.66683V11.3335C1.3335 13.1744 2.82588 14.6668 4.66683 14.6668H11.3335C13.1744 14.6668 14.6668 13.1744 14.6668 11.3335V4.66683C14.6668 2.82588 13.1744 1.3335 11.3335 1.3335Z"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.6668 7.5802C10.7491 8.13503 10.6543 8.70168 10.396 9.19954C10.1376 9.69741 9.7289 10.1011 9.22788 10.3533C8.72687 10.6055 8.1591 10.6933 7.60532 10.6042C7.05155 10.515 6.53997 10.2536 6.14336 9.85698C5.74674 9.46036 5.48529 8.94878 5.39618 8.39501C5.30707 7.84124 5.39484 7.27346 5.64701 6.77245C5.89919 6.27144 6.30292 5.86269 6.80079 5.60436C7.29865 5.34603 7.8653 5.25126 8.42013 5.33353C8.98608 5.41746 9.51003 5.68118 9.91459 6.08574C10.3192 6.4903 10.5829 7.01425 10.6668 7.5802Z"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.6665 4.3335H11.6732"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="hidden md:inline-block pt-0.5 ml-1.5 max-w-[120px] overflow-hidden overflow-ellipsis whitespace-nowrap">
              @{socials?.instagram}
            </span>
          </a>
        )}
        {socials?.facebook && (
          <a
            href={`https://facebook.com/${socials?.facebook}`}
            target="_blank"
            rel="noreferrer"
            className={`flex items-center text-sm font-medium transition-colors duration-75 hover:text-primary-500 ${
              mode === "light" ? "text-dark hover:text-primary-500" : "text-grey-divider hover:text-accent-yellow-500"
            }`}
          >
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
              <g clipPath="url(#clip0_1362:80)">
                <path
                  d="M12.117 2.31201H10.117C9.23297 2.31201 8.38512 2.6632 7.76 3.28832C7.13488 3.91344 6.78369 4.76129 6.78369 5.64535V7.64535H4.78369V10.312H6.78369V15.6453H9.45036V10.312H11.4504L12.117 7.64535H9.45036V5.64535C9.45036 5.46853 9.5206 5.29896 9.64562 5.17394C9.77064 5.04892 9.94021 4.97868 10.117 4.97868H12.117V2.31201Z"
                  stroke="currentColor"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1362:80">
                  <rect width="16" height="16" fill="currentColor" transform="translate(0.117188 0.978516)" />
                </clipPath>
              </defs>
            </svg>
            <span className="hidden md:inline-block pt-0.5 ml-1.5 max-w-[120px] overflow-hidden overflow-ellipsis whitespace-nowrap">
              @{socials?.facebook}
            </span>
          </a>
        )}
      </div> */}
    </footer>
  );
};

export default PublicFooter;
